/* globals document, window */
require('../pages/not_registered/_styles.scss');
const React = require('react');

const I18n = require('nordic/i18n');
const hydrate = require('nordic/hydrate');
const I18nProvider = require('nordic/i18n/I18nProvider');

const NotRegisteredView = require('../pages/not_registered/components/NotRegisteredView');
const DomainPreconnect = require('../components/domain-preconnect/DomainPreconnect');

const preloadState = window.__PRELOADED_STATE__;
const { translations } = preloadState;
const i18n = new I18n({ translations });

hydrate(
  <I18nProvider i18n={i18n}>
    <NotRegisteredView
      {...preloadState}
    />
  </I18nProvider>, document.getElementById('root-app'),
);

/* preconnects */
if (document.getElementById('preconnect-props')) {
  const preconnectProps = JSON.parse(document.getElementById('preconnect-props').getAttribute('data-props'));
  hydrate(
    React.createElement(DomainPreconnect, preconnectProps), document.getElementById('preconnect-app'),
  );
}
