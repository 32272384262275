const React = require('react');
const { string, bool, shape, arrayOf } = require('prop-types');
const { Head } = require('nordic/head');
const injectI18n = require('nordic/i18n/injectI18n');
const Script = require('nordic/script');
const serialize = require('serialize-javascript');
const { MelidataTrack } = require('nordic/melidata/melidata-track');

const { Button } = require('@andes/button');

const StyleAsset = require('../../../components/StyleAsset');
const CenterCard = require('../../../components/CenterCard');
const RegistrationLink = require('../../../components/RegistrationLink');
const LoginGA = require('../../../components/LoginGA');
const MercadoShopsHotjar = require('../../../components/MercadoShopsHotjar');
const CheckoutGA = require('../../../components/CheckoutGA');
const WordingManager = require('../../../wording-manager');
const ReplaceUrl = require('../../../components/ReplaceUrl');
const DomainPreconnect = require('../../../components/domain-preconnect/DomainPreconnect');

class NotRegisteredView extends React.Component {
  constructor(props) {
    super(props);
    this.wordings = WordingManager.wordings(props.i18n);
  }

  render() {
    const {
      translations,
      platform,
      action,
      track,
      navigation,
      mobile,
      actualUrl,
      preconnectUrls,
      urls,
    } = this.props;
    const preconnectProps = JSON.stringify({ urls: preconnectUrls });
    const { login_type } = navigation;
    const { not_registered } = this.wordings;
    const notRegisteredWordings = not_registered[login_type] || not_registered.default;
    const { title, buttons } = notRegisteredWordings.not_registered;

    return (
      <div className="not-registered_view container">
        <LoginGA
          navigation={navigation}
          page={`REGISTRATION_${login_type.toUpperCase()}`}
        />
        <CheckoutGA
          navigation={navigation}
          step="REGISTRATION"
          mobile={mobile}
        />
        <MelidataTrack path="/dummy" isDeferred /> { /* loads melidata scripts */}
        <MercadoShopsHotjar loginType={login_type} />
        <Head>
          <title>{title()}</title>
        </Head>
        <StyleAsset platformId={platform.id} viewName="not_registered" />
        <Script>
          {`
            window.__PRELOADED_STATE__ = ${serialize(
            {
              translations, platform, action, track, navigation, mobile, actualUrl, preconnectUrls, urls,
            },
            { isJSON: true },
          )};
          `}
        </Script>
        <CenterCard type="reduced-centered">
          <div className="center-card__header">
            <h1 className="center-card__title">{title()}</h1>
          </div>
          <div>
            <RegistrationLink
              id="registration-link"
              flavor="loud"
              text={buttons.primary()}
              track={track.createAccount}
              url={urls.registration}
            />
            <Button
              className="login-link"
              hierarchy="transparent"
              href={action}
            >
              {buttons.secondary()}
            </Button>
          </div>
        </CenterCard>
        <ReplaceUrl url={actualUrl} />
        <DomainPreconnect urls={preconnectUrls} />
        <div id="preconnect-props" data-props={preconnectProps} />
        <Script src="not_registered.js" />
      </div>
    );
  }
}

NotRegisteredView.propTypes = {
  platform: shape().isRequired,
  translations: shape().isRequired,
  action: string.isRequired,
  track: shape(),
  navigation: shape().isRequired,
  actualUrl: string.isRequired,
  mobile: bool.isRequired,
  preconnectUrls: arrayOf(string).isRequired,
  i18n: shape().isRequired,
  urls: shape({
    registration: string,
  }).isRequired,
};

NotRegisteredView.defaultProps = {
  track: {},
};

module.exports = injectI18n(NotRegisteredView);
