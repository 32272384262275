/* eslint-disable max-len */
const { a11y } = require('./a11y');
const { translateSecurityIssues } = require('./securityIssues');
const { tagContext } = require('../../services/data/enums');
const { help } = require('./help');
const { translatePasswordPage } = require('./password');
const { translateFactorBlockedPage } = require('./factorBlocked');

const actionsForNotRegisteredPage = i18n => ({
  primary: () => i18n.gettext('Crear cuenta'),
  secondary: () => i18n.gettext('Ingresar'),
});

const actionsForIdentifyPage = i18n => ({
  submit: () => i18n.gettext('Continuar'),
  register: () => i18n.gettext('Crear cuenta'),
  /* i18n-next-line except: ["en"] */
  continueAsGuestUser: () => i18n.gettext('Comprar como invitado'),
});

const actionsForPasswordPage = i18n => ({
  submit: () => i18n.gettext('Ingresar'),
  recover_pass: () => i18n.gettext('¿Olvidaste tu contraseña?'),
});

const actionsForReauthPasswordPage = i18n => ({
  submit: () => i18n.gettext('Continuar'),
  recover_pass: () => i18n.gettext('¿Olvidaste tu contraseña?'),
});

const defaultPassword = (i18n) => ({
  buttons: actionsForPasswordPage(i18n),
  title: (_, labelContext) => {
    const shouldRenderWithTags = labelContext === tagContext.CARD;
    const translator = shouldRenderWithTags ? i18n.jsx : i18n;
    const {
      replacementArguments: {
        platformId,
        firstName,
      },
    } = i18n;
    const tags = {
      0: '<span class="platform__label">',
      1: '</span>',
      2: firstName,
    };
    const settings = shouldRenderWithTags ? ({ tags }) : ['', ''];

    if (firstName && shouldRenderWithTags) {
      if (platformId === 'MP') {
        return translator.gettext('{2}, ahora ingresa tu contraseña de {0}Mercado Pago{1}', settings);
      }

      return translator.gettext('{2}, ahora ingresa tu contraseña de {0}Mercado Libre{1}', settings);
    }

    if (platformId === 'MP') {
      return translator.gettext('Ahora, tu contraseña de {0}Mercado Pago{1}', settings);
    }

    return translator.gettext('Ahora, tu contraseña de {0}Mercado Libre{1}', settings);
  },
});

const defaultWording = (i18n, type) => ({
  registered: {
    type,
    email_or_nickname: {
      title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
      buttons: actionsForIdentifyPage(i18n),
    },
    email_or_nickname_or_phone: {
      /* i18n-next-line except: ["en"] */
      title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
      buttons: actionsForIdentifyPage(i18n),
    },
    password: defaultPassword(i18n),
  },
  not_registered: {
    type,
    not_registered: {
      title: () => i18n.gettext('Ingresa a tu cuenta'),
      buttons: actionsForNotRegisteredPage(i18n),
    },
    email_or_nickname: {
      title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
      buttons: actionsForIdentifyPage(i18n),
    },
    email_or_nickname_or_phone: {
      /* i18n-next-line except: ["en"] */
      title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
      buttons: actionsForIdentifyPage(i18n),
    },
    password: defaultPassword(i18n),
  },
  password_greet: {
    type,
    password: {
      title: user => i18n.gettext('¡Hola, {0}! Para seguir, ingresa tu contraseña', user),
      buttons: actionsForPasswordPage(i18n),
    },
  },
  password_reauth: {
    type,
    password: {
      title: () => i18n.gettext('Para continuar ingresa tu contraseña'),
      buttons: actionsForReauthPasswordPage(i18n),
    },
  },
});

const defaultMshopStoreWording = (i18n, type) => ({
  type,
  email_or_nickname: {
    /* i18n-block-start except: ["en"] */
    title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
    subtitle: store => i18n.gettext('{0} utiliza la plataforma de pagos y la logística de envíos de Mercado Libre para que tu compra esté protegida.', store),
    buttons: actionsForIdentifyPage(i18n),
    payment_and_shipping: i18n.gettext('Procesamos el pago y envío con tu cuenta de'),
  },
  email_or_nickname_or_phone: {
    title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
    subtitle: store => i18n.gettext('{0} utiliza la plataforma de pagos y la logística de envíos de Mercado Libre para que tu compra esté protegida.', store),
    buttons: actionsForIdentifyPage(i18n),
    payment_and_shipping: i18n.gettext('Procesamos el pago y envío con tu cuenta de'),
  },
  password: {
    ...defaultPassword(i18n),
    payment_and_shipping: i18n.gettext('Procesamos el pago y envío con tu cuenta de'),
    /* i18n-block-end */
  },
});

const assembleWording = i18n => ({
  labels: {
    email_or_nickname: () => i18n.gettext('E-mail'),
    /* i18n-next-line except: ["en"] */
    email_or_nickname_or_phone: () => i18n.gettext('E-mail o teléfono'),
    password: () => i18n.gettext('Contraseña'),
    show: () => i18n.gettext('Mostrar'),
    hide: () => i18n.gettext('Ocultar'),
  },
  a11y: {
    ...a11y(i18n),
  },
  help: {
    ...help(i18n),
  },
  securityIssues: translateSecurityIssues(i18n),
  registered: {
    default: defaultWording(i18n, 'default').registered,
    explicit: defaultWording(i18n, 'login').registered,
    registration_v0: defaultWording(i18n, 'registration_v0').registered,
    registration_v3: defaultWording(i18n, 'registration_v3').registered,
    favorite: {
      type: 'favorite',
      email_or_nickname: {
        title: () => i18n.gettext('¡Hola! Para agregar favoritos, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('¡Hola! Para agregar favoritos, ingresa tu e‑mail o teléfono'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    question: {
      type: 'question',
      email_or_nickname: {
        title: () => i18n.gettext('¡Hola! Para preguntar, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('¡Hola! Para preguntar, ingresa tu e‑mail o teléfono'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    quote: {
      type: 'quote',
      email_or_nickname: {
        title: () => i18n.gettext('¡Hola! Para cotizar, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('¡Hola! Para cotizar, ingresa tu e‑mail o teléfono'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    share: {
      type: 'share',
      email_or_nickname: {
        title: () => i18n.gettext('¡Hola! Para compartir, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('¡Hola! Para compartir, ingresa tu e‑mail o teléfono'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    print: {
      type: 'print',
      email_or_nickname: {
        title: () => i18n.gettext('¡Hola! Para imprimir, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('¡Hola! Para imprimir, ingresa tu e‑mail o teléfono'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    publish: {
      type: 'publish',
      email_or_nickname: {
        title: () => i18n.gettext('¡Hola! Para publicar, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('¡Hola! Para publicar, ingresa tu e‑mail o teléfono'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    download: {
      type: 'download',
      email_or_nickname: {
        title: () => i18n.gettext('¡Hola! Para descargar, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('¡Hola! Para descargar, ingresa tu e‑mail o teléfono'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    contact: {
      type: 'contact',
      email_or_nickname: {
        title: () => i18n.gettext('¡Hola! Para contactar, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('¡Hola! Para contactar, ingresa tu e‑mail o teléfono'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    sell: {
      type: 'sell',
      email_or_nickname: {
        title: () => i18n.gettext('¡Hola! Para vender, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('¡Hola! Para vender, ingresa tu e‑mail o teléfono'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    buy: {
      type: 'buy',
      email_or_nickname: {
        title: () => i18n.gettext('¡Hola! Para comprar, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('¡Hola! Para comprar, ingresa tu e‑mail o teléfono'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    view_cart: {
      type: 'view_cart',
      email_or_nickname: {
        title: () => i18n.gettext('¡Hola! Para ver el carrito, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('¡Hola! Para ver el carrito, ingresa tu e‑mail o teléfono'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    add_cart: {
      type: 'add_cart',
      email_or_nickname: {
        title: () => i18n.gettext('¡Hola! Para agregar al carrito, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('¡Hola! Para agregar al carrito, ingresa tu e‑mail o teléfono'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    checkout_buy: {
      type: 'checkout_buy',
      email_or_nickname: {
        title: () => i18n.gettext('¡Hola! Para comprar, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('¡Hola! Para comprar, ingresa tu e‑mail o teléfono'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    checkout_auction: {
      type: 'checkout_auction',
      email_or_nickname: {
        title: () => i18n.gettext('¡Hola! Para ofertar, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('¡Hola! Para ofertar, ingresa tu e‑mail o teléfono'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    advance_real_state: {
      type: 'advance_real_state',
      email_or_nickname: {
        title: () => i18n.gettext('¡Hola! Para reservar, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('¡Hola! Para reservar, ingresa tu e‑mail o teléfono'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    advance_services: {
      type: 'advance_services',
      email_or_nickname: {
        title: () => i18n.gettext('¡Hola! Para reservar, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('¡Hola! Para reservar, ingresa tu e‑mail o teléfono'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    advance_motors: {
      type: 'advance_motors',
      email_or_nickname: {
        title: () => i18n.gettext('¡Hola! Para reservar, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('¡Hola! Para reservar, ingresa tu e‑mail o teléfono'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    callme_real_state: {
      type: 'callme_real_state',
      email_or_nickname: {
        title: () => i18n.gettext('¡Hola! Para pedir que te llamen, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('¡Hola! Para pedir que te llamen, ingresa tu e‑mail o teléfono'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    point_redeem_coupon: defaultWording(i18n, 'point_redeem_coupon').registered,
    google_play: defaultWording(i18n, 'google_play').registered, // not used but to avoid 500
    buy_ms_store: defaultMshopStoreWording(i18n, 'buy_ms_store'),
    guest_login_ml: defaultMshopStoreWording(i18n, 'guest_login_ml'),
    hybrid_guest: defaultMshopStoreWording(i18n, 'hybrid_guest'),
    login_choice: defaultMshopStoreWording(i18n, 'login_choice'),
    guest_login_mp: defaultWording(i18n, 'guest_login_mp').registered,
    mshops_high_risk: defaultMshopStoreWording(i18n, 'mshops_high_risk'),
    consumer_credits: {
      type: 'consumer_credits',
      email_or_nickname: {
        /* i18n-block-start except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para activar Mercado Crédito, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('¡Hola! Para activar Mercado Crédito, ingresa tu e‑mail o teléfono'),
        /* i18n-block-end */
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    negative_traffic: {
      type: 'negative_traffic',
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    cbt: defaultWording(i18n, 'cbt').registered,
    point_buy: {
      type: 'point_buy',
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: {
        buttons: actionsForReauthPasswordPage(i18n),
        title: defaultPassword(i18n).title,
      },
    },
    vis_motor_contact: defaultWording(i18n, 'vis_motor_contact').registered,
    vis_credits_simulation: {
      type: 'vis_credits_simulation',
      email_or_nickname: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para simular tu crédito, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para simular tu crédito, ingresa tu e‑mail o teléfono'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    faqs: defaultWording(i18n, 'faqs').registered,
    digital_sus: {
      type: 'digital_sus',
      email_or_nickname: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: () => i18n.gettext('¡Hola! Para activar tus beneficios, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: () => i18n.gettext('¡Hola! Para activar tus beneficios, ingresa tu e‑mail o teléfono'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: () => i18n.gettext('Para activar tus beneficios, ingresa tu contraseña'),
        /* i18n-block-end */
        buttons: actionsForPasswordPage(i18n),
      },
    },
    ifpe: {
      type: 'ifpe',
      email_or_nickname: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: () => i18n.gettext('¡Hola! Para seguir a Mercado Pago Wallet, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: () => i18n.gettext('¡Hola! Para seguir a Mercado Pago Wallet, ingresa tu e‑mail o teléfono'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: () => i18n.gettext('¡Hola! Para seguir a Mercado Pago Wallet ingresa tu contraseña'),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    registration_link: defaultWording(i18n, 'registration_link').registered,
    checkout_pro: defaultWording(i18n, 'checkout_pro').registered,
    registration_fasttrack: defaultWording(i18n, 'registration_fasttrack').registered,
    registration_fasttrack_off: defaultWording(i18n, 'registration_fasttrack_off').registered,
    fraud_remedies: defaultWording(i18n, 'fraud_remedies').registered,
  },
  not_registered: {
    default: defaultWording(i18n, 'default').not_registered,
    explicit: defaultWording(i18n, 'login').not_registered,
    registration_v0: defaultWording(i18n, 'registration_v0').not_registered,
    registration_v3: defaultWording(i18n, 'registration_v3').not_registered,
    favorite: {
      type: 'favorite',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para agregar favoritos, ingresa a tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    question: {
      type: 'question',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para preguntar, ingresa a tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    quote: {
      type: 'quote',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para cotizar, ingresa a tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    share: {
      type: 'share',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para compartir, ingresa a tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    print: {
      type: 'print',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para imprimir, ingresa a tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    publish: {
      type: 'publish',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para publicar, ingresa a tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    download: {
      type: 'download',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para descargar, ingresa a tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    contact: {
      type: 'contact',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para contactar, ingresa a tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    sell: {
      type: 'sell',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para vender, ingresa a tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    buy: {
      type: 'buy',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para comprar, ingresa a tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    view_cart: {
      type: 'view_cart',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para ver el carrito, ingresa a tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    add_cart: {
      type: 'add_cart',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para agregar al carrito, ingresa a tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    checkout_buy: {
      type: 'checkout_buy',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para comprar, ingresa a tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    checkout_auction: {
      type: 'checkout_auction',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para ofertar, ingresa a tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    advance_real_state: {
      type: 'advance_real_state',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para reservar, ingresa a tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    advance_services: {
      type: 'advance_services',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para reservar, ingresa a tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    advance_motors: {
      type: 'advance_motors',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para reservar, ingresa a tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    callme_real_state: {
      type: 'callme_real_state',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para pedir que te llamen, ingresa a tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    point_redeem_coupon: {
      type: 'point_redeem_coupon',
      not_registered: {
        title: () => i18n.gettext('Crea una cuenta o ingresa con tu usuario de Mercado Pago o Mercado Libre'),
        buttons: {
          primary: () => i18n.gettext('Crear cuenta'),
          secondary: () => i18n.gettext('Ingresar con mi usuario'),
        },
      },
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    google_play: { // not used but to avoid 500
      type: 'google_play',
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    buy_ms_store: defaultMshopStoreWording(i18n, 'buy_ms_store'),
    guest_login_ml: defaultMshopStoreWording(i18n, 'guest_login_ml'),
    guest_login_mp: defaultWording(i18n, 'guest_login_mp').not_registered,
    hybrid_guest: defaultMshopStoreWording(i18n, 'hybrid_guest'),
    login_choice: defaultMshopStoreWording(i18n, 'login_choice'),
    mshops_high_risk: defaultMshopStoreWording(i18n, 'mshops_high_risk'),
    consumer_credits: {
      type: 'consumer_credits',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para activar Mercado Crédito, primero ingresa a tu cuenta'),
        buttons: {
          primary: () => i18n.gettext('Crear cuenta'),
          secondary: () => i18n.gettext('Ingresar con mi usuario'),
        },
      },
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    negative_traffic: {
      type: 'negative_traffic',
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    cbt: defaultWording(i18n, 'cbt').not_registered,
    point_buy: {
      type: 'point_buy',
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: {
        buttons: actionsForReauthPasswordPage(i18n),
        title: defaultPassword(i18n).title,
      },
    },
    vis_motor_contact: {
      type: 'vis_motor_contact',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para contactar al vendedor, ingresa a tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    vis_credits_simulation: {
      type: 'vis_credits_simulation',
      not_registered: {
        /* i18n-next-line except: ["en"] */
        title: () => i18n.gettext('¡Hola! Para simular tu crédito, ingresa a tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        title: () => i18n.gettext('Ingresa tu e-mail para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        title: () => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: defaultPassword(i18n),
    },
    faqs: defaultWording(i18n, 'faqs').not_registered,
    digital_sus: {
      type: 'digital_sus',
      not_registered: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: () => i18n.gettext('¡Hola! Para activar tus beneficios, ingresa a tu cuenta'),
        buttons: actionsForNotRegisteredPage(i18n),
      },
      email_or_nickname: {
        /* i18n-next-line except: ["pt-BR", "en"] */
        title: () => i18n.gettext('¡Hola! Para activar tus beneficios, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: () => i18n.gettext('¡Hola! Para activar tus beneficios, ingresa tu e‑mail o teléfono'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: () => i18n.gettext('Para activar tus beneficios, ingresa tu contraseña'),
        /* i18n-block-end */
        buttons: actionsForPasswordPage(i18n),
      },
    },
    ifpe: {
      type: 'ifpe',
      email_or_nickname: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: () => i18n.gettext('¡Hola! Para seguir a Mercado Pago Wallet, ingresa tu e‑mail'),
        buttons: actionsForIdentifyPage(i18n),
      },
      email_or_nickname_or_phone: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: () => i18n.gettext('¡Hola! Para seguir a Mercado Pago Wallet, ingresa tu e‑mail o teléfono'),
        buttons: actionsForIdentifyPage(i18n),
      },
      password: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: () => i18n.gettext('¡Hola! Para seguir a Mercado Pago Wallet ingresa tu contraseña'),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    registration_link: defaultWording(i18n, 'registration_link').not_registered,
    checkout_pro: defaultWording(i18n, 'checkout_pro').not_registered,
    registration_fasttrack: defaultWording(i18n, 'registration_fasttrack').not_registered,
    registration_fasttrack_off: defaultWording(i18n, 'registration_fasttrack_off').not_registered,
    fraud_remedies: defaultWording(i18n, 'fraud_remedies').not_registered,
  },
  manual_removal: {
    title: i18n.gettext('Por seguridad, bloqueamos el acceso a tu cuenta'),
    subtitle: i18n.gettext('Bloqueamos momentáneamente tu cuenta porque detectamos un ingreso sospechoso y es probable que la estén usando sin tu permiso. Te contactaremos lo antes posible para confirmar tu identidad y desbloquearla de forma segura.'),
    buttons: {
      primary: i18n.gettext('Volver al inicio'),
    },
  },
  congrats_greet: (user) => ({
    title: i18n.gettext('¡Listo, {0}!', user),
    subtitle: i18n.gettext('¿Quieres cambiar tu contraseña ahora?'),
    buttons: {
      continue: i18n.gettext('Ahora no'),
      change_password: i18n.gettext('Cambiar mi contraseña'),
    },
  }),
  social_off: {
    /* i18n-block-start except: ["en"] */
    title: provider => i18n.gettext('El acceso a través de {0} ya no está disponible', provider),
    subtitle: provider => i18n.gettext(
      'Ingresa con el e-mail vinculado a tu cuenta de {0} y selecciona la opción No sé mi clave para crear una nueva clave.', // eslint-disable-line max-len
      /* i18n-block-end */
      provider,
    ),
  },
  password_greet: {
    default: defaultWording(i18n, 'default').password_greet,
    explicit: defaultWording(i18n, 'login').password_greet,
    registration_v0: defaultWording(i18n, 'registration_v0').password_greet,
    registration_v3: defaultWording(i18n, 'registration_v3').password_greet,
    registration_v0_legacy: defaultWording(i18n, 'registration_v0_legacy').password_greet,
    registration_v0_pj: defaultWording(i18n, 'registration_v0_pj').password_greet,
    favorite: {
      type: 'favorite',
      password: {
        title: user => i18n.gettext('¡Hola, {0}! Para agregar favoritos, ingresa tu contraseña', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    question: {
      type: 'question',
      password: {
        title: user => i18n.gettext('¡Hola, {0}! Para preguntar, ingresa tu contraseña', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    quote: {
      type: 'quote',
      password: {
        title: user => i18n.gettext('¡Hola, {0}! Para cotizar, ingresa tu contraseña', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    share: {
      type: 'share',
      password: {
        title: user => i18n.gettext('¡Hola, {0}! Para compartir, ingresa tu contraseña', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    print: {
      type: 'print',
      password: {
        title: user => i18n.gettext('¡Hola, {0}! Para imprimir, ingresa tu contraseña', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    publish: {
      type: 'publish',
      password: {
        title: user => i18n.gettext('¡Hola, {0}! Para publicar, ingresa tu contraseña', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    download: {
      type: 'download',
      password: {
        title: user => i18n.gettext('¡Hola, {0}! Para descargar, ingresa tu contraseña', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    contact: {
      type: 'contact',
      password: {
        title: user => i18n.gettext('¡Hola, {0}! Para contactar, ingresa tu contraseña', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    sell: {
      type: 'sell',
      password: {
        title: user => i18n.gettext('¡Hola, {0}! Para vender, ingresa tu contraseña', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    buy: {
      type: 'buy',
      password: {
        title: user => i18n.gettext('¡Hola, {0}! Para comprar, ingresa tu contraseña', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    view_cart: {
      type: 'view_cart',
      password: {
        title: user => i18n.gettext('¡Hola, {0}! Para ver el carrito, ingresa tu contraseña', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    add_cart: {
      type: 'add_cart',
      password: {
        title: user => i18n.gettext('¡Hola, {0}! Para agregar al carrito, ingresa tu contraseña', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    checkout_buy: {
      type: 'checkout_buy',
      password: {
        title: user => i18n.gettext('¡Hola, {0}! Para comprar, ingresa tu contraseña', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    checkout_auction: {
      type: 'checkout_auction',
      password: {
        title: user => i18n.gettext('¡Hola, {0}! Para ofertar, ingresa tu contraseña', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    advance_real_state: {
      type: 'advance_real_state',
      password: {
        title: user => i18n.gettext('¡Hola, {0}! Para reservar, ingresa tu contraseña', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    advance_services: {
      type: 'advance_services',
      password: {
        title: user => i18n.gettext('¡Hola, {0}! Para reservar, ingresa tu contraseña', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    advance_motors: {
      type: 'advance_motors',
      password: {
        title: user => i18n.gettext('¡Hola, {0}! Para reservar, ingresa tu contraseña', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    callme_real_state: {
      type: 'callme_real_state',
      password: {
        title: user => i18n.gettext('¡Hola, {0}! Para pedir que te llamen, ingresa tu contraseña', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    point_redeem_coupon: {
      type: 'point_redeem_coupon',
      password: {
        title: user => i18n.gettext('¡Hola, {0}! Para seguir, ingresa tu contraseña', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    google_play: {
      type: 'google_play',
      password: {
        title: user => i18n.gettext('¡Hola {0}! Ingresa tu contraseña para pagar en Google con Mercado Pago', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    buy_ms_store: {
      type: 'buy_ms_store',
      password: {
      /* i18n-block-start except: ["en"] */
        payment_and_shipping: i18n.gettext('Procesamos el pago y envío con tu cuenta de'),
        title: user => i18n.gettext('Ingresa tu e-mail o teléfono para iniciar sesión', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    mshops_high_risk: {
      type: 'mshops_high_risk',
      password: {
        payment_and_shipping: i18n.gettext('Procesamos el pago y envío con tu cuenta de'),
        /* i18n-block-end */
        title: user => i18n.gettext('Ingresa la contraseña de tu cuenta de Mercado Libre', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    guest_login_ml: defaultMshopStoreWording(i18n, 'guest_login_ml'),
    hybrid_guest: defaultMshopStoreWording(i18n, 'hybrid_guest'),
    login_choice: defaultMshopStoreWording(i18n, 'login_choice'),
    guest_login_mp: defaultWording(i18n, 'guest_login_mp').password_greet,
    consumer_credits: {
      type: 'consumer_credits',
      password: {
        title: user => i18n.gettext('¡Hola, {0}! Para activar Mercado Crédito, primero ingresa tu contraseña', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    negative_traffic: {
      type: 'negative_traffic',
      password: {
        title: () => i18n.gettext('Para continuar ingresa tu contraseña'),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    cbt: defaultWording(i18n, 'cbt').password_greet,
    point_buy: {
      type: 'point_buy',
      password: {
        title: user => i18n.gettext('¡Hola, {0}! Para seguir, ingresa tu contraseña', user),
        buttons: actionsForReauthPasswordPage(i18n),
      },
    },
    vis_motor_contact: defaultWording(i18n, 'vis_motor_contact').password_greet,
    vis_credits_simulation: {
      type: 'vis_credits_simulation',
      password: {
        title: user => i18n.gettext('¡Hola, {0}! Para simular tu crédito, ingresa tu contraseña', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    faqs: defaultWording(i18n, 'faqs').password_greet,
    digital_sus: {
      type: 'digital_sus',
      password: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: user => i18n.gettext('¡Hola, {0}! Para activar tus beneficios, ingresa tu contraseña', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    ifpe: {
      type: 'ifpe',
      password: {
        /* i18n-next-line except: ["en", "pt-BR"] */
        title: user => i18n.gettext('¡Hola, {0}! Para seguir a Mercado Pago Wallet ingresa tu contraseña', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
    registration_link: defaultWording(i18n, 'registration_link').password_greet,
    checkout_pro: defaultWording(i18n, 'checkout_pro').password_greet,
    registration_fasttrack: defaultWording(i18n, 'registration_fasttrack').password_greet,
    registration_fasttrack_off: defaultWording(i18n, 'registration_fasttrack_off').password_greet,
    fraud_remedies: defaultWording(i18n, 'fraud_remedies').password_greet,
  },
  password_reauth: {
    default: defaultWording(i18n, 'default').password_reauth,
    registration_v0: defaultWording(i18n, 'registration_v0').password_reauth,
    registration_v3: defaultWording(i18n, 'registration_v3').password_reauth,
    google_play: {
      type: 'google_play',
      password: {
        title: user => i18n.gettext('¡Hola {0}! Ingresa tu contraseña para pagar en Google con Mercado Pago', user),
        buttons: actionsForPasswordPage(i18n),
      },
    },
  },
  expired_password: {
    title: i18n.gettext('Tu clave venció'),
    last_time_description: i18n.gettext('Pasó mucho tiempo desde la última vez que la usaste.'),
    use_other_method_description: i18n.gettext('No te preocupes, puedes ingresar con otro método de verificación.'),
    buttons: {
      continue: i18n.gettext('Continuar'),
    },
  },
  errors: {
    email_not_found: () => i18n.gettext('Revisa el dato que ingresaste.'),
    nickname_not_found: () => i18n.gettext('Revisa el dato que ingresaste.'),
    /* i18n-next-line except: ["en"] */
    phone_not_found: () => i18n.gettext('Este número de teléfono no está asociado a una cuenta'),
    missing_response: () => i18n.gettext('Completa este dato.'),
    user_required: () => i18n.gettext('Completa este dato.'),
    user_incomplete_registration: () => {
      const settings = {
        tags: {
          0: '<a class="input-error__link" onClick="window.onIncompleteRegistrationClick()">',
          1: '</a>',
        },
      };

      return i18n.jsx.gettext(
        'Tu cuenta todavía no existe. {0}Completa los pasos{1} para terminar de crearla.',
        settings,
      );
    },
    password_invalid: () => i18n.gettext('Revisa tu contraseña.'),
    password_required: () => i18n.gettext('Completa este dato.'),
    /* i18n-block-start except: ["en"] */
    captcha_word_required: () => i18n.gettext('Completa este paso para continuar.'),
    captcha_response_invalid: () => i18n.gettext('Completa este paso para continuar.'),
    /* i18n-block-end */
    invalid_user_site: () => i18n.gettext('Ingresaste un e-mail de '),
    user_site_platform_mismatch: () => i18n.gettext('Ingresaste un e-mail de otra plataforma.'),
    operator_not_allowed: () => i18n.gettext('Como operador de la cuenta, no tienes permiso para ingresar.'),
    server_error: () => i18n.gettext('¡Ups! Hubo un problema. Vuelve a intentarlo.'),
    /* i18n-block-start except: ["en", "pt-BR"] */
    email_invalid: () => i18n.gettext('El dato que ingresaste no es un e-mail.'),
    email_mismatch: () => i18n.gettext('Este e-mail no coincide con el de tu cuenta.'),
    /* i18n-block-end */
  },
  faqs_section: {
    /* i18n-next-line except: ["en"] */
    link_to_login_faqs: () => i18n.gettext('Necesito ayuda para ingresar'),
  },
  recaptcha: {
    protected_by: () => i18n.gettext('Protegido por reCAPTCHA'),
    privacy: () => i18n.gettext('Privacidad'),
    conditions: () => i18n.gettext('Condiciones'),
  },
  notFound: {
    titleMp: i18n.gettext('Lo que querías ver no existe o lo movimos de lugar'),
    titleMl: i18n.gettext('Parece que esta página no existe'),
    mpLink: i18n.gettext('Ver operaciones'),
  },
  serverError: {
    titleMp: i18n.gettext('Oh, no, algo anduvo mal'),
    titleMl: i18n.gettext('¡Ups! Hubo un error'),
    descriptionMp: i18n.gettext('Ya empezamos a trabajar en solucionarlo.'),
    descriptionMl: i18n.gettext('Inténtalo de nuevo más tarde'),
  },
  emailAddressVerification: {
    /* i18n-next-line except: ["en"] */
    title: i18n.gettext('Ingresa el e-mail asociado a tu cuenta'),
    label: i18n.gettext('E-mail'),
    action: i18n.gettext('Continuar'),
  },
  footer: {
    link: () => i18n.gettext('Cómo cuidamos tu privacidad (abrirá una nueva ventana)'),
    copyright: (year, isIfpe) => (
      isIfpe
        /* i18n-next-line except: ["en", "pt-BR"] */
        ? i18n.gettext('Este Canal de Instrucción o ambiente es operado por Mercado Libre, S.A. de C.V., Institución de Fondos de Pago Electrónico, sociedad autorizada para operar como institución de fondos de pago electrónico que proporciona los servicios relacionados con tu cuenta Mercado Pago Wallet.')
        : i18n.gettext('Copyright © 1999-{0} MercadoLibre S.R.L.', year) /* i18n-this-line except: ["en"] */
    ),
  },
  cameraNotFound: {
    /* i18n-block-start except: ["en"] */
    title: i18n.gettext('Tu computadora no tiene una cámara para iniciar sesión con reconocimiento facial'),
    description: {
      firstParagraph: i18n.gettext('Para que podamos verificar que la cuenta te pertenece, activa Código QR como método de verificación desde la app de Mercado Pago y vuelve a iniciar sesión en esta computadora.'),
      secondParagraph: i18n.gettext('De lo contrario, usa un dispositivo con cámara para iniciar sesión con reconocimiento facial.'),
    },
    tooltip: {
      link: i18n.gettext('¿Cómo activar Código QR?'),
      firstStep: i18n.gettext('Abre la app de Mercado Pago y, desde Tu perfil, ingresa a Seguridad.'),
      secondStep: i18n.gettext('Accede a Verificación en 2 pasos y activa Código QR.'),
    },
    /* i18n-block-end */
    continue: i18n.gettext('Entendido'),
  },
  appVersionUnsupported: {
    /* i18n-next-line except: ["en"] */
    title: i18n.gettext('Por favor, actualiza la app para continuar'),
    buttons: {
      primary: i18n.gettext('Actualizar app'),
    },
  },
  browserNotSupported: {
    /* i18n-block-start except: ["en"] */
    title: i18n.gettext('Activa Código QR en la app de Mercado Pago para iniciar sesión desde esta app'),
    description: i18n.gettext('Para que podamos confirmar que la cuenta te pertenece, activa Código QR como método de verificación en la app de Mercado Pago y vuelve a iniciar sesión desde esta app.'),
    tooltip: {
      link: i18n.gettext('¿Cómo activar Código QR?'),
      firstStep: i18n.gettext('Abre la app de Mercado Pago y, desde Tu perfil, ingresa a Seguridad.'),
      secondStep: i18n.gettext('Accede a Verificación en 2 pasos y activa Código QR.'),
    },
    /* i18n-block-end */
    buttons: { primary: i18n.gettext('Entendido') },
  },
  /* i18n-block-start except: ["en"] */
  factorSkipped: {
    title: i18n.gettext('Notamos que tienes problemas con tu contraseña, te ayudaremos a iniciar sesión de otra forma'),
  },
  potentialAtoTarget: (platformName) => ({
    title: i18n.gettext('Por seguridad, no se puede acceder a {0} dentro de esta aplicación', platformName),
    buttons: {
      continue: i18n.gettext('Entendido'),
    },
  }),
  /* i18n-block-end */
});

module.exports.wordings = i18n => assembleWording(i18n);
exports.translatePasswordPage = translatePasswordPage;
exports.translateFactorPage = translateFactorBlockedPage;
